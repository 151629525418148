import styled from "styled-components";

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  width: 90px;
  background-color: #ffdc0f;
  height: 100vh;
  padding: 1rem;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  box-shadow: -7px 0 15px 0px inset #00000073;

  span {
    transform: rotate(-90deg);
    font-size: 30px;
    font-weight: 900;
    display: flex;
    margin-top: 50vh;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

import styled, { css } from "styled-components";

interface CarrinhoProps {
  isOpen: boolean;
}

export const BoxJaCobrou = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 2rem !important;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  strong {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
`;

export const Carrinho = styled.div<CarrinhoProps>`
  position: fixed;
  top: 57px;
  height: calc(100vh - 57px);
  right: 0;
  background-color: #282829;
  padding: 0.75rem;
  box-sizing: border-box;
  width: 35%;
  box-shadow: -8px 0px 10px -5px #101010;

  > p {
    color: #fff;
  }

  h1 {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  #listaItens {
    height: calc(100vh - 360px);
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 20px;
  }

  .sem-itens {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h2 {
      color: #d6d6d6;
      margin-top: 50px;
    }

    p {
      text-align: center;
      width: 300px;
      color: #d6d6d6;
      line-height: 1.5rem;
    }
  }

  .resumo-itens {
    display: flex;
    flex-direction: column;
    width: 100%;

    > strong {
      color: #fff;
    }
  }

  #acoes-conta {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .pregar {
      background-color: #ffdc0f;
      font-size: 1rem;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 10px;
      display: flex;
      gap: 5px;
      border: 0;
      box-shadow: 0 0 5px #000;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .pagar {
      background-color: #4caf50;
      font-size: 1rem;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 10px;
      display: flex;
      gap: 5px;
      color: #fff;
      border: 0;
      box-shadow: 0 0 5px #000;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    right: -100%;

    ${({ isOpen }) =>
      isOpen
        ? css`
            right: 0;
          `
        : css`
            right: -100%;
          `}
  }
`;

export const ContaFinal = styled.div`
  background-color: #3d3d3d;
  border-radius: 5px;
  padding: 0.5rem;

  h3 {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 5px;
    padding: 5px;
  }

  table {
    td:last-child {
      text-align: right;
    }

    width: 100%;
    tbody td {
      color: #b8b8b8;
      padding: 10px 5px;
    }

    tfoot td {
      color: #b8b8b8;
      font-size: 1rem;
      border-top: 1px dashed #b8b8b8;
      padding: 10px 5px;
      font-weight: 600;
    }

    tfoot td:last-child {
      color: #fff;
      font-weight: 900;
    }
  }
`;

export const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 20px;
  flex: 1;

  button {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    padding: 12px 24px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px #000;
  }

  span {
    color: #fff;
  }

  strong {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
`;

export const OtherOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  button.voltar {
    background-color: #9e9e9e;
  }

  button {
    background-color: #4caf50;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    gap: 5px;
    color: #fff;
    border: 0;
    box-shadow: 0 0 5px #000;
  }

  button.warning {
    background-color: red;
  }
`;

export const CarrinhoButton = styled.button`
  display: none;

  @media screen and (max-width: 768px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;

    font-size: 1.3rem;
    font-weight: bold;
    padding: 6px 20px;
    background: #ffdc0f;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 5px #000;

    span {
      position: absolute;
      top: -10px;
      left: -10px;
      font-size: 1rem;
      background-color: #000;
      width: 25px;
      height: 25px;
      color: #fff;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const PaymentMethod = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;

  h2 {
    color: #fff;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      color: #fff;
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 600;
    }

    input {
      font-size: 1rem;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #fff;
      background: none;
      color: #fff;
    }
  }
`;

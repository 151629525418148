import { ItemCarrinhoComponent } from "./Item";
import {
  Carrinho,
  ContaFinal,
  OtherOptions,
  CarrinhoButton,
  BoxJaCobrou,
  PaymentMethod,
} from "./style";

import { BsCashCoin } from "react-icons/bs";
import { FaRegCreditCard, FaCartShopping } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import { IoArrowBackOutline } from "react-icons/io5";
import { PiNoteDuotone } from "react-icons/pi";

import { useCarrinho } from "../../Hooks/carrinho";
import { useEffect, useState } from "react";
import api from "../../Services/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useUser } from "../../Hooks/user";

export function CarrinhoComponent() {
  //import functions/uses
  const user = useUser();
  const items = useCarrinho();
  const dispatch = useDispatch();

  //Component functions
  const qtd = items.reduce((acc, item) => acc + item.qtd, 0);
  const total = items.reduce(
    (acc, item) => acc + parseFloat(item.unitPrice) * item.qtd,
    0
  );

  const formatCurrency = (rawValue: any) => {
    const onlyNumbers = rawValue.replace(/\D/g, ""); // Remove tudo que não for número
    const formatted = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(onlyNumbers / 100);
    return formatted;
  };

  const [carrinhoOpen, setCarrinhoOpen] = useState<boolean>(false);
  const [jaPagou, setJaPagou] = useState<boolean>(false);
  const [saldoRestante, setSaldoRestante] = useState<number>(total);
  const [payments, setPayments] = useState<{
    card_machine: string;
    money: string;
    pay_later: string;
  }>({
    card_machine: "",
    money: "",
    pay_later: "",
  });

  useEffect(() => {
    if (total > 0) {
      setPayments({
        ...payments,
        card_machine: formatCurrency(total.toFixed(2)),
      });
    }
  }, [total]);

  //Handle functions
  const handlePayOrder = async (
    items: any,
    payments: { card_machine: string; money: string; pay_later: string }
  ) => {
    const { card_machine, money, pay_later } = payments;
    const data = JSON.stringify({
      user_id: user.user_code,
      payment_method: "",
      items: items,
      card_machine: card_machine.replace(".", "").replace(",", "."),
      money: money.replace(".", "").replace(",", "."),
      pay_later: pay_later.replace(".", "").replace(",", "."),
    });

    api
      .post("orders", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((data) => {
        if (data.data.success) {
          setCarrinhoOpen(false);
          setJaPagou(false);
          toast.success("Pedido finalizado com sucesso!");
          dispatch({
            type: "carrinho/clear",
          });
          dispatch({
            type: "user/login",
            payload: {
              user_code: null,
            },
          });
          setCheckout(false);
          setPayments({ card_machine: "", money: "", pay_later: "" });

          api
            .get("/confs/impressora_url")
            .then((conf) => {
              console.log(conf);
              console.log(`http://${conf.data.valor}/${data.data.id}`);
              axios
                .get(`http://${conf.data.valor}/${data.data.id}`)
                .then((sucess) => {})
                .catch((err) => {
                  console.log(err);
                  //toast.warning("Impressora desligada ou sem comunicação");
                });
            })
            .catch((err) => {
              toast.warning("IP da impressora não configurado");
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertToDecimal = (value: string) => {
    // Remove o separador de milhar "." e substitui a vírgula decimal "," por "."
    const numericValue = value.replace(/\./g, "").replace(",", ".");

    // Converte a string para um número decimal
    return Number(numericValue);
  };

  // Define as quantidades pagas
  const handleCheckPayment = (
    payment_method: "card_machine" | "money" | "pay_later",
    value: string
  ) => {
    setPayments({ ...payments, [payment_method]: formatCurrency(value) });
  };

  useEffect(() => {
    const pagoCardMachine = convertToDecimal(payments.card_machine) || 0;
    const pagoMoney = convertToDecimal(payments.money) || 0;
    const pagoPayLater = convertToDecimal(payments.pay_later) || 0;

    const saldoPago = pagoCardMachine + pagoMoney + pagoPayLater;
    setSaldoRestante(total - saldoPago);
  }, [payments, total]);

  // States
  const [checkout, setCheckout] = useState<boolean>(false);

  return (
    <>
      <Carrinho isOpen={carrinhoOpen}>
        {checkout ? (
          <>
            <h1>Fechar pedido</h1>
            {!jaPagou ? (
              <BoxJaCobrou>
                <h1>Já cobrou??</h1>
                <strong>
                  Total: R${" "}
                  {total.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                </strong>
                <OtherOptions>
                  <button onClick={() => setJaPagou(true)}>
                    Sim, já cobrei
                  </button>
                  <button
                    className="warning"
                    type="button"
                    onClick={() => setCheckout(false)}
                  >
                    <IoArrowBackOutline /> Voltar
                  </button>
                </OtherOptions>
              </BoxJaCobrou>
            ) : (
              <>
                <p>
                  Escolha uma forma de pagamento para concluir, ou{" "}
                  <u>voltar para adicionar mais itens</u>.
                </p>

                <PaymentMethod>
                  <h2>
                    Restante:{" "}
                    <u>
                      R${" "}
                      {saldoRestante.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </u>
                  </h2>
                  <div>
                    <label htmlFor="">
                      <FaRegCreditCard /> Maquininha
                    </label>
                    <input
                      type="tel"
                      placeholder="R$ 0,00"
                      value={payments.card_machine}
                      onChange={(e) =>
                        handleCheckPayment("card_machine", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="">
                      <MdAttachMoney /> Dinheiro
                    </label>
                    <input
                      type="tel"
                      placeholder="R$ 0,00"
                      value={payments.money}
                      onChange={(e) =>
                        handleCheckPayment("money", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="">
                      <PiNoteDuotone /> Prego
                    </label>
                    <input
                      type="tel"
                      placeholder="R$ 0,00"
                      value={payments.pay_later}
                      onChange={(e) =>
                        handleCheckPayment("pay_later", e.target.value)
                      }
                    />
                  </div>
                </PaymentMethod>
                <OtherOptions>
                  <button
                    className="voltar"
                    type="button"
                    onClick={() => setCheckout(false)}
                  >
                    <IoArrowBackOutline /> Voltar
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePayOrder(items, payments)}
                  >
                    <BsCashCoin /> Finalizar
                  </button>
                </OtherOptions>
              </>
            )}
          </>
        ) : (
          <>
            <h1>Resumo</h1>
            {items.length === 0 ? (
              <div className="sem-itens">
                <h2>Ainda sem nenhum item :( </h2>
                <p>
                  Use a busca ao lado e clique em um item para adicionar e
                  iniciar a venda
                </p>
              </div>
            ) : (
              <div className="resumo-itens">
                <strong>{qtd} produtos</strong>
                <div id="listaItens">
                  {items.map((item) => (
                    <ItemCarrinhoComponent
                      {...item}
                      key={`item${item.product_id}-variant${item.variant_id}`}
                    />
                  ))}
                </div>
                <ContaFinal>
                  <h3>Conta</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td>Produtos</td>
                        <td>
                          R${" "}
                          {total.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>
                          R${" "}
                          {total.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </ContaFinal>
                <div id="acoes-conta">
                  <button
                    className="pagar"
                    onClick={() => {
                      setCheckout(true);
                      setJaPagou(false);
                    }}
                  >
                    <BsCashCoin /> Fechar pedido
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Carrinho>
      <CarrinhoButton onClick={() => setCarrinhoOpen(!carrinhoOpen)}>
        <FaCartShopping /> {carrinhoOpen ? `Esconder` : "Ver"} itens{" "}
        <span>{qtd}</span>
      </CarrinhoButton>
    </>
  );
}

import { useEffect, useState } from "react";
import { HeaderComponent } from "../../Components/Header";
import { LateralComponent } from "../../Components/Lateral";
import Moment from "moment";

import { Content, FormGroup, ResultadoCaixa } from "./style";
import api from "../../Services/api";
import { useDispatch } from "react-redux";

export default function FecharCaixa() {
  const dispatch = useDispatch();

  const [initialDate, setInitialDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [show, setShow] = useState<boolean>(false);

  const [startMoney, setStartMoney] = useState<number>(0);

  const [money, setMoney] = useState<number>(0);
  const [maquininha, setMaquininha] = useState<number>(0);
  const [prego, setPrego] = useState<number>(0);

  useEffect(() => {
    (async () => {
      setShow(false);

      const caixa = await api.get("/caixa");
      if (caixa.data.data.status === "fechado") {
        let startdate = caixa.data.data.abertura;
        let enddate = caixa.data.data.fechamento;
        setInitialDate(startdate);
        setEndDate(enddate);
        setStartMoney(caixa.data.data.saldo_inicial);

        const resultado = await api.get(
          `orders?startdate=${Moment(startdate).format(
            "YYYY-MM-DD HH:mm:ss"
          )}&enddate=${Moment(enddate).format("YYYY-MM-DD HH:mm:ss")}`
        );
        //console.log(`orders?startdate=${Moment(initialDate).format('YYYY-MM-DD HH:mm:ss')}&enddate=${Moment(endDate).format('YYYY-MM-DD HH:mm:ss')}`)
        console.log(resultado);

        setShow(true);

        const prego_api = resultado.data.reduce((acc: number, order: any) => {
          if (order.pay_later > 0) {
            return order.pay_later + acc;
          }
          return acc;
        }, 0);
        setPrego(prego_api);

        const money_api = resultado.data.reduce((acc: number, order: any) => {
          if (order.money > 0) {
            return order.money + acc;
          }
          return acc;
        }, 0);
        setMoney(money_api);

        const maquininha_api = resultado.data.reduce(
          (acc: number, order: any) => {
            if (order.card_machine > 0) {
              return order.card_machine + acc;
            }
            return acc;
          },
          0
        );
        setMaquininha(maquininha_api);
      }
    })();
  }, []);

  const handleOpenNewStore = async () => {
    const saldo_inicial = prompt("Qual o valor inicial?");
    if (saldo_inicial) {
      await api.post(`/caixa/open`, {
        saldo_inicial,
      });
      dispatch({
        type: "caixa/open",
      });
    }
  };

  return (
    <>
      <HeaderComponent title="Caixa fechado" />
      <LateralComponent />

      <Content>
        <FormGroup>
          <div>
            <label htmlFor="">Data abertura</label>
            <div>
              <b>{Moment(initialDate).format("DD/MM/YYYY [às] HH:mm")}</b>
            </div>
          </div>
          <div>
            <label htmlFor="">Data fechamento</label>
            <div>
              <b>{Moment(endDate).format("DD/MM/YYYY [às] HH:mm")}</b>
            </div>
          </div>
          <div>
            <label htmlFor="">Saldo inicial</label>
            <div>
              <b>
                R${" "}
                {startMoney.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </b>
            </div>
          </div>
          <button onClick={() => handleOpenNewStore()}>Abrir Novo Caixa</button>
        </FormGroup>
        {show && (
          <ResultadoCaixa>
            <div className="box">
              <h2>Dinheiro</h2>
              <h1 style={{ textAlign: "center" }}>
                <small>
                  {`R$ ${startMoney.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}`}{" "}
                  +{" "}
                </small>
                <small>
                  {` R$ ${money.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}`}{" "}
                  =
                </small>
                <br />
                R${" "}
                {(money + startMoney).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </h1>
            </div>
            <div className="box">
              <h2>Maquininha</h2>
              <h1>
                R${" "}
                {maquininha.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                })}
              </h1>
            </div>
            <div className="box">
              <h2>Prego</h2>
              <h1>
                R$ {prego.toLocaleString("pt-br", { minimumFractionDigits: 2 })}
              </h1>
            </div>
          </ResultadoCaixa>
        )}
      </Content>
    </>
  );
}
